import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Link as MuiLink } from '@material-ui/core';

const useFooterStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
  },
}));

const Footer = () => {
  const footerClasses = useFooterStyles();

  return (
    <footer className={footerClasses.root}>
      <Box py={8}>
        <Typography variant="body2">
          CFC Answers is a ministry of{' '}
          <MuiLink href="https://rlcf.church">rlcf.church</MuiLink>.
        </Typography>
      </Box>
    </footer>
  );
};

export default Footer;
