import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'gatsby';

const useLogoStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(6),
  },
  link: {
    width: '80%',
    maxWidth: '128px',
  },
  image: {
    width: '100%',
    display: 'block',
    margin: 0,
  },
}));

const Header = () => {
  const logoClasses = useLogoStyles();

  return (
    <header>
      <div className={logoClasses.root}>
        <Link className={logoClasses.link} to="/">
          <img
            className={logoClasses.image}
            src="/graphics/logo.png"
            alt="CFC Answers"
          />
        </Link>
      </div>
    </header>
  );
};

export default Header;
