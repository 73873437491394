import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Container } from '@material-ui/core';

import Header from './Header';
import Footer from './Footer';

const Layout = ({ container, children }) => {
  useEffect(() => {
    window.refTagger = {
      settings: {
        tooltipStyle: 'dark',
        bibleVersion: 'NASB',
        bibleReader: 'bible.faithlife',
        socialSharing: ['twitter', 'facebook'],
      },
    };

    const script = document.createElement('script');
    script.src = 'https://api.reftagger.com/v2/RefTagger.js';
    script.type = 'text/javascript';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Box mt={5}>
        <Header />

        <Container maxWidth={container}>{children}</Container>
      </Box>

      <Footer />
    </>
  );
};

Layout.propTypes = {
  container: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  container: 'lg',
};

export const withLayout = (Component, layoutProps = {}) => (props) => (
  <Layout {...layoutProps}>
    <Component {...props} />
  </Layout>
);

export default Layout;
